import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// import data from '../../data/hot16v2.json'
import data from '../../data/hot16v2_20200625.json'
import { Box, Button, Text } from 'rebass/styled-components'
import * as d3 from 'd3'
import { Details } from './Details/Details'
import { filterFunctions } from './helpers'
import { SVG } from './RadialChart'
import Layout from '../../layouts/main'
import theme from '../theme'

export default (props) => {
  const nodeMouseOver = function (d) {
    d3.select(`#leaf-${d.data.id}`)
      .attr('r', isDesktop ? 30 : 15)
      .attr('z-index', 30)
    setHovered(d)
    markParentsRecursively(d)
  }
  const nodeMmouseLeave = function (d) {
    d3.select(`#leaf-${d.data.id}`).transition().attr('r', 5).attr('z-index', 0)
  }

  const nodeClick = (d) => {
    d3.selectAll('circle')
      .data(root.descendants())
      .transition()
      .attr('r', isDesktop ? 5 : 3)

    setSelected(d)
    const x = d.y * Math.cos(d.x)
    const y = d.y * Math.sin(d.x)
    setTransformZoom(`translate(${width / 2 + -y}, ${height / 2 + x}) scale(1)`)
    // setHovered(d)
    d3.selectAll(`circle`)
      .attr('r', isDesktop ? 5 : 3)
      .attr()
    markParentsRecursively(d, true)
    // markParentsRecursively(d)
    // markChildren(d)
  }

  const isBrowser = typeof window !== 'undefined'
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : '100vw')
  const [height, setHeight] = useState(isBrowser ? window.innerWidth : '100vh')
  const isDesktop = width > 768
  // const radius = Math.max(width, height) / 2
  const radius = Math.min(width, height) / (isDesktop ? 1.5 : 2)
  useEffect(() => {
    if (isBrowser) {
      const width = window.innerWidth
      const height = window.innerHeight
      setWidth(width)
      setHeight(height)
      if (!props.transform) {
        setTransformZoom(`translate(${width / 2}, ${height / 2}) scale(1)`)
      }
    }
  }, [])

  useEffect(() => {
    if (props.clicked) {
      const d = visibleNodes.find((n) => n.data.name === props.clicked)
      if (!d) {
        return
      }
      const x = d.y * Math.cos(d.x)
      const y = d.y * Math.sin(d.x)
      // setTransformZoom(
      //   `translate(${width / 2 + -y}, ${height / 2 + x}) scale(${
      //     props.clickedZoom || 1
      //   })`
      // )
      // setHovered(d)
      d3.selectAll(`circle`)
        .attr('r', isDesktop ? 5 : 3)
        .attr()
      markParentsRecursively(d, true)
      // markChildren(d)
    }
  }, [props.clicked])

  const [filterFunction, setFilterFunction] = useState(
    () => props.filterFunction || filterFunctions.video
  )
  const [selected, setSelected] = useState({})
  const [hovered, setHovered] = useState({})
  const initialTransform =
    props.initialTransform || `translate(${width / 2}, ${height / 2}) scale(1)`
  const [transformZoom, setTransformZoom] = useState(initialTransform)

  const tree = d3
    .tree()
    .size([Math.PI * 2, radius])
    .separation((a, b) => (a.parent == b.parent ? 1.2 : 2) / (a.depth / 100))
  // .separation((a, b) => (a.parent == b.parent ? 1 : 1.5))

  const dataTransformed = d3.hierarchy(data[0])
  const root = tree(dataTransformed)
  const nodes = root.descendants()
  const visibleNodes = useMemo(
    () =>
      nodes.filter(filterFunction).filter((n) => {
        return props.depthFilter ? n.depth < props.depthFilter : true
      }),
    [filterFunction, width, height, isBrowser, props]
  )
  const links = root.links()
  const visibleLinks = useMemo(
    () =>
      links
        .filter((link) => filterFunction(link.target))
        .filter((n) =>
          props.depthFilter ? n.source.depth < props.depthFilter - 1 : true
        ),
    [filterFunction, width, height, isBrowser, props]
  )
  const resetZoom = () => {
    setTransformZoom(initialTransform)
  }
  const MemoizedSVG = useMemo(
    () => (
      <Box style={{ zIndex: 2, position: 'fixed' }}>
        <SVG
          visibleNodes={visibleNodes}
          visibleLinks={visibleLinks}
          transformZoom={props.transform || transformZoom}
          nodeMouseOver={nodeMouseOver}
          nodeMmouseLeave={nodeMmouseLeave}
          nodeClick={nodeClick}
          hovered={hovered}
          width={width}
          height={height}
          isDesktop={isDesktop}
          clicked={props.clicked}
        />
      </Box>
    ),
    [
      visibleNodes,
      visibleLinks,
      transformZoom,
      hovered,
      width,
      height,
      isBrowser,
      props,
      props.clicked,
    ]
  )
  useEffect(() => {
    const svg = d3.select('#tree-chart')
    const centerX = isBrowser ? width / 2 : 0
    const centerY = isBrowser ? height / 2 : 0

    svg.call(
      d3.zoom().on('zoom', function () {
        setTransformZoom(d3.event.transform.translate(centerX, centerY))
      })
    )
  }, [
    visibleNodes,
    visibleLinks,
    transformZoom,
    hovered,
    width,
    height,
    isBrowser,
    width,
    height,
    isBrowser,
  ])

  const nodeParents = (node) => {
    if (!node) {
      return []
    }
    if (node.parent && node.parent.parent) {
      return [node.parent].concat(nodeParents(node.parent))
    }
    return [node.parent]
  }
  const MemoizedDetails = useMemo(
    () => (
      <Details
        nodes={nodes}
        resetZoom={resetZoom}
        setFilterFunction={setFilterFunction}
        filterFunction={filterFunction}
        selected={selected}
        nodeClick={nodeClick}
        hovered={hovered}
        isDesktop={isDesktop}
        isBrowser={isBrowser}
      />
    ),
    [selected, filterFunction, isDesktop, isBrowser, props.disableInteraction]
  )
  return (
    <Layout>
      <Wrapper {...props}>
        {!props.hideDetails && MemoizedDetails}
        {MemoizedSVG}
        {/*<svg width={width} height={height}>*/}
        {/*  /!*<ForceDirected links={links} nodes={nodes} />*!/*/}
        {/*  <ForceDirected2 links={links} nodes={nodes} />*/}
        {/*</svg>*/}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  ${(props) => (props.disableInteraction ? 'pointer-events: none;' : '')};

  circle {
    opacity: 0;
    animation: appear 0.7s forwards;
  }

  path {
    opacity: 0;
    animation: appear2 0.4s forwards;
    animation-delay: 1s;
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }

  @keyframes appear2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const markParentsRecursively = (leaf, isRoot, small) => {
  const isDesktop = window.innerWidth > 768
  if (isRoot) {
    d3.selectAll('#zoom text').remove()
  }

  d3.select('#zoom')
    .append('g')
    .attr(
      'transform',
      `rotate(${(leaf.x * 180) / Math.PI - 90}) translate(${leaf.y},0)`
    )
    .append('text')
    .attr('id', `label-${leaf.id}`)
    .text(leaf.data.name)
    .style('font-size', isRoot ? (isDesktop ? 20 : 7) : 5)
    // .attr('stroke', theme.colors.primary)
    .attr('fill', theme.colors.secondary)
    .attr(
      'transform',
      `rotate(${-((leaf.x * 180) / Math.PI - 90)}) translate(${
        isDesktop ? '0, 0' : '0, -0'
      })`
    )

  d3.select('#leaf-' + leaf.data.id)
    .transition()
    .duration(isRoot ? 1000 : 0)
    .attr('r', isRoot ? (isDesktop ? 20 : 8) : isDesktop ? 12 : 5)

    .attr('fill', theme.colors.secondary)

  if (leaf.parent) {
    markParentsRecursively(leaf.parent)
  }
}

export const markChildren = (leaf) => {
  const elements = (leaf.data && leaf.data.children) || leaf.children || []
  elements.forEach((child) => {
    d3.select('#leaf-' + child.id)
      .transition()
      .delay(100)
      .attr('r', 15)
  })
}
