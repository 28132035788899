import React, { useEffect, useState } from 'react'
import { Button, Flex, Link, Text, Box } from 'rebass/styled-components'
import styled from 'styled-components'
import SubscriptionForm from '../../SubscriptionForm'
import Hot16Logo from './Hot16Logo'
import Filters from './Filters'
import Tree from './Tree'
import Controls from './Controls'
import InfoModal from './InfoModal'

const getNominationsArray = (node = {}, prev = []) => {
  if (node.parent) {
    return getNominationsArray(node.parent, prev.concat([node.parent]))
  } else return prev
}

export function Details(props) {
  const {
    setFilterFunction,
    selected,
    hovered,
    filterFunction,
    nodeClick,
    nodes,
    isDesktop,
    isBrowser,
  } = props
  const nomiationsList = getNominationsArray(selected)
  const [infoModalOpen, setInfoModalOpen] = useState(false)

  return (
    <>
      {/*<Hot16Logo />*/}

      {/*{isDesktop && (*/}
      {/*  <Filters*/}
      {/*    setFilterFunction={setFilterFunction}*/}
      {/*    filterFunction={filterFunction}*/}
      {/*  />*/}
      {/*)}*/}

      <Controls
        setInfoModalOpen={setInfoModalOpen}
        nodeClick={nodeClick}
        resetZoom={props.resetZoom}
        nodes={nodes}
        isDesktop={isDesktop}
      />

      {/*{hovered && (*/}
      {/*  <HoveredInfo fontSize={[4, 6, 8]}>*/}
      {/*    {hovered.data && hovered.data.name}*/}
      {/*  </HoveredInfo>*/}
      {/*)}*/}

      <Tree
        nomiationsList={nomiationsList}
        selected={selected}
        nodeClick={nodeClick}
        isDesktop={isDesktop}
      />

      <InfoModal
        infoModalOpen={infoModalOpen}
        setInfoModalOpen={setInfoModalOpen}
        isDesktop={isDesktop}
      />
    </>
  )
}
