import { Box, Flex, Link, Text } from 'rebass/styled-components'
import SubscriptionForm from '../../SubscriptionForm'
import React from 'react'
import styled from 'styled-components'

export default ({ setInfoModalOpen, infoModalOpen }) => (
  <InfoModal isOpen={infoModalOpen} onClick={() => setInfoModalOpen(false)}>
    <InfoModalContent>
      <CloseModal onClick={() => setInfoModalOpen(false)}>x</CloseModal>

      <InfoModalContent2 onClick={(e) => e.stopPropagation()}>
        <Text>
          Grafika przedstawia nominacje i nagrania w ramach akcji
          #hot16challenge2.
        </Text>

        <Text mt={3}>
          Dokładniejszy opis akcji, podczas której zebrano ponad 3,5mln złotych
          na wsparcie służby zdrowia znajdziesz na stronie{' '}
          <Link
            href={'http://hot16challenge.network'}
            target={'_blank'}
            style={{ textDecoration: 'underline' }}
          >
            hot16challenge.network
          </Link>
        </Text>

        <Text mt="5">
          Masz pytania? Napisz do mnie na{' '}
          <a href={'mailto:mateusz@sawka.pro'}>mateusz@sawka.pro</a>
        </Text>

        {/*<Text mt="5">*/}
        {/*  Pomóż mi uzupełnić wykres o brakujące nagrania wypełniając{' '}*/}
        {/*  <a*/}
        {/*    style={{ textDecoration: 'underline' }}*/}
        {/*    href={*/}
        {/*      'https://docs.google.com/forms/d/e/1FAIpQLScDYjY51z8VNjhqCYaqV3zRz_iZBUad75Fp1KAedSbTKYOwAQ/viewform?usp=sf_link'*/}
        {/*    }*/}
        {/*    target={'_blank'}*/}
        {/*  >*/}
        {/*    ten formularz*/}
        {/*  </a>*/}
        {/*</Text>*/}

        {/*<Text mt={5}>*/}
        {/*  Zostaw adres email a poinformuję Cię na bieżąco o nowych projektach*/}
        {/*  takich jak ten czy{' '}*/}
        {/*  <Link*/}
        {/*    href={'http://www.sawka.pro/ranking'}*/}
        {/*    style={{ textDecoration: 'underline' }}*/}
        {/*    target={'_blank'}*/}
        {/*  >*/}
        {/*    ranking słownictwa raperów*/}
        {/*  </Link>*/}
        {/*</Text>*/}
        {/*<SubscriptionForm />*/}
      </InfoModalContent2>
    </InfoModalContent>
  </InfoModal>
)

const InfoModal = styled(Box)`
  padding-left: 100px;
  padding-top: 100px;
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  transition: 1s all;
  right: ${(props) => (props.isOpen ? 0 : '-100vw')};
`

const InfoModalContent = styled(Flex)`
  position: absolute;
  width: calc(100vw - 20px);
  left: 20px;
  //top: 100px;
  //top: 100px;
  //padding-top: 100px;
  justify-content: center;
  align-items: center;
  background: black;
  height: calc(100vh - 100px);
  overflow: scroll;
`

const InfoModalContent2 = styled(Box)`
  position: absolute;
  padding: 2em;
  flex-direction: column;
  max-width: 768px;
  margin: auto;
  justify-content: space-around;
  height: 100%;
`

const CloseModal = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  margin-left: 5px;
  padding-right: 25px;
  padding-bottom: 20px;
  z-index: 300;
`
