import HOT16 from '../components/hot16/hot16'
import React from 'react'
import Hot16Logo from '../components/hot16/Details/Hot16Logo'

export default () => (
  <div>
    <div style={{ position: 'absolute' }}>
      <Hot16Logo
        style={{
          pointerEvents: 'none',
          // marginTop: 20,
          position: 'fixed',
          top: 20,
          //   zIndex: 666,
          //   opacity: currentStep.noLogo ? 0.1 : 1,
        }}
      />
    </div>
    <HOT16 />
  </div>
)
